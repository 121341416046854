import { EventEmitter, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../../../../../../../src/environments/environment";
import { EnvName } from "@utils/models/environment/env-name";
import { isPlatformBrowser } from "@angular/common";

declare let gtag: Function;

enum GAProperty {
  Android = "G-P0DNVJD41W",
  AndroidDebug = "G-GLK5KB6R5N",
  IOS = "G-8XQ6C4FKJT",
  IOSDebug = "G-8QFT46XW40",
  Web = "G-718QDWDD3E", // fan.at - APP only - Web Datastream
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {

  public pageViewEvent = new EventEmitter<string>();

  private isWebview = false;
  private ignoredRoutes: string[] = [];

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public init(isWebview: boolean, ignoredRoutes: string[] = []) {
    this.isWebview = isWebview;
    this.ignoredRoutes = ignoredRoutes;
  }

  public onRouteChange(url: string): void {
    if (!isPlatformBrowser(this.platformId) || this.checkIgnoredNavigation()) {
      return;
    }
    this.sendPageView(url);
  }

  public sendPageView(
    url?: string,
    queryParams?: URLSearchParams,
    blockEvent = false
  ) {
    url = url || this.router.url;
    if (queryParams) {
      const queryString = url.includes("?")
        ? `&${queryParams.toString()}`
        : `?${queryParams.toString()}`;
      url += queryString;
    }
    if (!blockEvent) {
      this.pageViewEvent.next(url);
    }
    if (environment.name !== EnvName.Prod) {
      return;
    }
    if (!this.isWebview) {
      this.sendGAPageViews(url);
    }
  }

  public sendGAPageViews(url: string): void {
    this.sendGAPageView(url, GAProperty.Web);
  }

  private sendGAPageView(url: string, property: GAProperty): void {
    if (typeof gtag === "undefined" || !gtag) {
      console.warn("[Google Tag] Could not send page view! gtag is undefined!");
      return;
    }
    gtag("event", "screen_view", {
      page_path: url,
      anonymize_ip: true,
      send_to: property,
    });
  }

  private checkIgnoredNavigation(): boolean {
    const url = window.location.href;
    let ignore = url.endsWith("#");
    this.ignoredRoutes.forEach((route) => {
      if (url.includes(route)) {
        ignore = true;
      }
    });
    return ignore;
  }
}
