import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthDialogService} from '@modules/dialog/services/auth-dialog.service';

export enum DIALOG_HASH_ROUTES {
  RECRUIT = '#werde-botschafter',
  REGISTER = '#registrieren',
  LOGIN = '#anmelden',
}

@Injectable({
  providedIn: 'root'
})
export class DialogHashRouteService {

  private isDialogOpen = false;

  constructor( private matDialog: MatDialog,
               private authDialogService: AuthDialogService,
               private route: ActivatedRoute,
               private router: Router ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.handleRoute(ev.urlAfterRedirects);
      }
    });
  }

  private handleRoute(route: string): void {
    if (route.endsWith(DIALOG_HASH_ROUTES.REGISTER)) {
      this.openRegisterDialog();
    }
    if (route.endsWith(DIALOG_HASH_ROUTES.LOGIN)) {
      this.openLoginDialog();
    }
  }

  private openRegisterDialog(): void {
    this.isDialogOpen = true;
    this.authDialogService.openRegistration({ registration: true });
    this.removeFragment();

  }

  private openLoginDialog(): void {
    this.isDialogOpen = true;
    this.authDialogService.openLogin();
    this.removeFragment();
  }

  private removeFragment(): void {
    this.router.navigate([], {
      relativeTo: this.route, preserveFragment: false, replaceUrl: true
    });
  }

}
