import {Picture, PictureSize} from '@core/services/picture';
import {
  INewsBroadcastProperties,
  INewsObjectIdProperties,
  INewsPost,
  INewsPostCreator,
  INewsPostUITags,
  INewsSelectedTags
} from '@api/models/news/news';
import {INewsInfo} from '@api/models/news/news-info';
import {IVideo} from '@api/models/video/video';
import {Sex} from '@shared/models/sex';

export enum CreatorType {
  Newsprofile = 'newsprofile',
  Team = 'team'
}

export class Creator {
  private readonly placeHolderImage = 'assets/icons/fan.at/team-dummy-male.svg';

  creatorid: string;
  readable_id?: string;
  name: string;
  type: CreatorType;
  pictures: Picture[] = [];

  constructor(json: any) {
    this.creatorid = json.creatorid;
    this.name = json.name;
    this.type = json.type;
    this.readable_id = json.readable_id;

    if (json.pictures != null && json.pictures instanceof Array) {
      for (let i = 0; i < json.pictures.length; i++) {
        const picture = new Picture(json.pictures[i]);
        this.pictures.push(picture);
      }
    }
  }

  public isCreatedByTeam(): boolean {
    return this.type != null && this.type === CreatorType.Team;
  }

  public isCreatedByNewsProfile(): boolean {
    return this.type != null && this.type === CreatorType.Newsprofile;
  }

  public getLogoOfSize(size: PictureSize): string {
    return Picture.getImageOfSize(this.pictures, size, 'logo', this.placeHolderImage);
  }
}

enum AuthorType {
  UserProfile = 'user',
  Internal = 'internal',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter'
}

export class Author {
  authorid: string;
  name: string;
  type: string;
  pictures: Picture[] = [];

  constructor(json: any) {
    this.authorid = json.authorid;
    this.name = json.name;
    this.type = json.type;

    if (json.pictures != null && json.pictures instanceof Array) {
      for (let i = 0; i < json.pictures.length; i++) {
        const picture = new Picture(json.pictures[i]);
        this.pictures.push(picture);
      }
    }
  }

  public postedViaFacebook(): boolean {
    return this.type === AuthorType.Facebook;
  }

  public postedViaInstagram(): boolean {
    return this.type === AuthorType.Instagram;
  }

  public postedViaTwitter(): boolean {
    return this.type === AuthorType.Twitter;
  }

}

export enum NewsCategoryType {
  GENERAL = 'Allgemein',
  TOTR = 'Elf der Runde'
}

export class TeamTag {
  _id: string;
  ui_name: string;
  readable_id: string;

  constructor(json: any) {
    this._id = json._id;
    this.ui_name = json.ui_name;
    this.readable_id = json.readable_id;
  }
}

export class NewsMediaProperty {
  videoURL: string;
  facebookURL: string;
  twitterURL: string;
}

export class NewsEntry implements Partial<INewsPost> {
  _id: string;
  id: string;
  teamids: string[];
  leagueids: string[];
  stateids: string[];
  countryids: string[];
  title: string;
  pretitle: string;
  preview_text: string;
  text: string;
  content: string; // copy of text
  category: string;
  creator: Creator | any;
  author: Author;
  info: INewsInfo;
  ui_tags: INewsPostUITags;
  pictures: Picture[] = [];
  media?: NewsMediaProperty;
  short_url: string;
  external_link: string;
  timestamp: number;
  date: number; // copy of timestamp
  symbol: string;
  sponsor: string;
  sponsored: boolean;
  selected: INewsObjectIdProperties;
  broadcast: INewsBroadcastProperties;
  authorid: string;
  notification_sent: boolean;
  picture_upload_finished: boolean;
  complete: boolean;
  userid: string;
  _created: number;
  _modified: number;
  type: string;
  selected_tags?: INewsSelectedTags;
  videos_native: IVideo[];
  global?: boolean;
  sexes: string[];
  web_url?: string;

  public constructor(init?: Partial<NewsEntry | INewsPost>) {
    this.pictures = [];
    let picturesJson = [];
    if (init.pictures) {
      picturesJson = JSON.parse(JSON.stringify(init.pictures));
    }
    init.pictures = [];

    Object.assign(this, init);

    this.id = this._id;
    this.content = this.text;
    this.date = this.timestamp;

    for (let i = 0; i < picturesJson.length; i++) {
      const image = new Picture(picturesJson[i]);
      this.pictures.push(image);
    }

    if (init.creator != null) {
      this.creator = new Creator(init.creator);
    }
    if (init.author != null) {
      this.author = new Author(init.author);
    }
  }

  getImagesUrls(size: string): string[] {
    if (this.pictures == null || this.pictures.length === 0) {
      return [];
    }

    const images = this.pictures.filter( picture => { return picture.subtype === 'image'});
    if (images.length === 0) {
      return [];
    }

    if (size === 'small') {
      return images.map(image => {
        return image.getSmallImage().url;
      });
    } else if (size === 'medium') {
      return images.map(image => {
        return image.getMediumImage().url;
      });
    } else if (size === 'big') {
      return images.map(image => {
        return image.getBigImage().url;
      });
    }

    return [];
  }

  convertNewsPostingTime() {
    const time = this.date;
    const date = typeof time === 'object' ? time : new Date(time);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const seconds = Math.round((today.getTime() - date.getTime()) / 1000);
    const minutes = Math.round(seconds / 60);
    const hoursAgo = Math.round(minutes / 60);
    const isThisYear = today.getFullYear() === date.getFullYear();
    const remainingDays = Math.abs(Math.round((date.getTime() - today.getTime()) / DAY_IN_MS));

    function convertDate(dateParam, postedVia, thisYear) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (thisYear) {
        return 'am ' + day + '.' + month + '.'  + postedVia;
      } else {
        return 'am ' + day + '.' + month + '.' + year + postedVia;
      }
    }

    let postedViaSocialMediaText;
    if (this.author.postedViaInstagram()) {
      postedViaSocialMediaText = ' via Instagram';
    } else {
      postedViaSocialMediaText = '';
    }

    if (seconds < 65) {
      return  'vor einer Minute' + postedViaSocialMediaText;
    } else if (minutes < 60) {
      return 'vor ' + minutes +  ' Minuten' + postedViaSocialMediaText;
    } else if (hoursAgo <= 24) {
      if (hoursAgo === 1) {
        return  'vor ' + hoursAgo +  ' Stunde' + postedViaSocialMediaText;
      } else {
        return  'vor ' + hoursAgo +  ' Stunden' + postedViaSocialMediaText;
      }
    } else if (hoursAgo > 24 && remainingDays <= 7) {
      if (remainingDays === 1) {
        return 'vor ' +  remainingDays + ' Tag';
      } else {
        return 'vor ' +  remainingDays + ' Tagen';
      }
    } else if (remainingDays > 7) {
      return convertDate(date, postedViaSocialMediaText, true);
    } else if (!isThisYear) {
      return convertDate(date, postedViaSocialMediaText, false);
    }
  }
}
