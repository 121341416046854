import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ComponentType, OverlayConfig} from '@angular/cdk/overlay';
import {
  OverlayDisplayStrategy, OverlayRefWrapper,
} from './models/overlay';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {BOTTOM_SHEET_DEFAULT_CONFIG, DIALOG_DEFAULT_CONFIG, SNACKBAR_DEFAULT_CONFIG} from '@lib-modules/overlays/models/overlay-config';
import {Router} from '@angular/router';
import {BottomSheetWrapperComponent} from '@lib-modules/overlays/wrappers/bottom-sheet-wrapper/bottom-sheet-wrapper.component';
import {LayoutService} from '../../../utils/src/lib/services/layout/layout.service';
import {DialogWrapperComponent} from '@lib-modules/overlays/wrappers/dialog-wrapper/dialog-wrapper.component';
import {isPlatformServer} from '@angular/common';


@Injectable({
  providedIn: "root"
})
export class OverlayService {

  displayStrategy: OverlayDisplayStrategy = OverlayDisplayStrategy.Mixed;

  private static applyDefaultConfig(config: OverlayConfig, defaultConfig: OverlayConfig): OverlayConfig {
    // use config where given, else use default config's values
    return Object.assign({}, defaultConfig, config);
  }

  constructor( private bottomSheet: MatBottomSheet,
               private dialog: MatDialog,
               private snackbar: MatSnackBar,
               private router: Router,
               private layoutService: LayoutService,
               @Inject(PLATFORM_ID) protected platformId: Object ) { }

  private setRouterFragment() {
    this.router.navigate([], {fragment: ''}); // add empty fragment for back navigation to work
    // this.router.onSameUrlNavigation = 'ignore'; // on back, nav, component gets reloaded - change if ignore doesnt break anything
  }

  private restoreCleanRouterState() {
    this.router.navigate([], {fragment: undefined});
  }

  private handleOverlayClose() {
    this.restoreCleanRouterState();
    this.layoutService.setBodyScroll(true);
  }

  /**
   * Adds target component to config
   * @param contentComponent component to be displayed in wrapper
   * @param config of overlay
   */
  private addContentToConfig(contentComponent: ComponentType<unknown>, config: MatDialogConfig | MatBottomSheetConfig) {
    config.data = config.data ?? { };
    config.data.component = contentComponent;
  }

  private openBottomSheet(component: ComponentType<unknown>, config: MatBottomSheetConfig): MatBottomSheetRef {
    config = OverlayService.applyDefaultConfig(config, BOTTOM_SHEET_DEFAULT_CONFIG) as MatBottomSheetConfig;
    this.addContentToConfig(component, config);
    const ref = this.bottomSheet.open(BottomSheetWrapperComponent, config);
    ref.afterDismissed().subscribe(() => this.handleOverlayClose());
    this.layoutService.setBodyScroll(false);
    return ref;
  }

  private openDialog(component: ComponentType<unknown>, config: MatDialogConfig): MatDialogRef<unknown> {
    config = OverlayService.applyDefaultConfig(config, DIALOG_DEFAULT_CONFIG) as MatDialogConfig;
    this.addContentToConfig(component, config);

    const ref = this.dialog.open(DialogWrapperComponent, config);

    ref.afterClosed().subscribe(() => this.handleOverlayClose());
    this.layoutService.setBodyScroll(false);
    return ref;
  }

  private openSnackBar(component: ComponentType<unknown>, config: MatSnackBarConfig) {
    config = OverlayService.applyDefaultConfig(config, SNACKBAR_DEFAULT_CONFIG) as MatSnackBarConfig;
    const ref = this.snackbar.openFromComponent(component, config);
    ref.afterDismissed().subscribe(() => this.handleOverlayClose());
  }

  public open( component: ComponentType<unknown>,
               data?: any,
               config?: OverlayConfig,
               setRouterFragment = false ): OverlayRefWrapper {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    config = config ?? {};
    let ref = null;
    switch (this.displayStrategy) {
      case OverlayDisplayStrategy.BottomSheet:
        ref = this.openBottomSheet(component, { ...config, data } as MatBottomSheetConfig);
        break;
      case OverlayDisplayStrategy.Dialog:
        ref = this.openDialog(component, { ...config, data } as MatDialogConfig);
        break;
      case OverlayDisplayStrategy.SnackBar:
        this.openSnackBar(component, { ...config, data } as MatSnackBarConfig);
        break;
      default:
        if (window.document.body.offsetWidth < 768) {
          ref = this.openBottomSheet(component, { ...config, data } as MatBottomSheetConfig);
        } else {
          ref = this.openDialog(component, { ...config, data } as MatDialogConfig);
        }
    }
    if (setRouterFragment) {
      this.setRouterFragment();
    }
    return new OverlayRefWrapper(ref);
  }
}
