import {Injectable} from '@angular/core';
import {APIService, ChangeUserPassword} from './api.service';
import {HttpClient} from '@angular/common/http';
import {User} from '@shared/models/user';
import {StateService} from '@core/services/state.service';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {UserHttpService} from '@api/http/user/user-http.service';
import {SnackbarService} from '@core/services/snackbar.service';
import {IUser} from '@api/models/user/user';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {FollowEntityType} from '@api/models/user/user-follow';
import {LibVideoPlayerService} from '@components/video-player/services/lib-video-player.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static readonly INVALID_USERNAME_OR_PASSWORD = 'INVALID_USERNAME_OR_PASSWORD';
  static readonly INVALID_PARAMETERS = 'INVALID_PARAMETERS';
  static readonly OTHER_ERROR = 'ERROR';
  static readonly TOKEN_EXPIRED = 'TOKEN_EXPIRED';

  public currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser: User;
  public digestLoginPromise: Promise<IUser>;

  private route(): string {
    return this.apiService.getBaseURL() + 'users/';
  }
  private authenticationRoute(): string {
    return this.apiService.getBaseURL() + 'users/login/';
  }

  private checkUsernameRoute(username: string): string {
    return this.route() + 'check/username/' + username;
  }

  constructor( private http: HttpClient,
               private appStateService: StateService,
               private apiService: APIService,
               private userHttpService: UserHttpService,
               private snackbar: SnackbarService,
               private router: Router,
               private videoPlayerService: LibVideoPlayerService ) {}

  isLoggedIn() {
    if (!StateService.isBrowser) {
      return false;
    }
    const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
    return !!token;
  }

  /***************************** HTTP GET FUNCTIONS *****************************/

  checkUsernameAvailability(username: string): Promise<any> {
    return this.http.get(this.checkUsernameRoute(username)).toPromise().catch(error =>
      console.log(error)
    );
  }

  public async digestLoginUser(userId: string, timestamp: number, digest: string): Promise<void> {
    try {
      this.digestLoginPromise = this.userHttpService.postDigestLoginUser(userId, timestamp, digest);
      const response = await this.digestLoginPromise;
      if (!response) {
        this.handleFailedLogin();
        return;
      }
      await this.handleSuccessfulLogin(User.createFromJson(response), false);
    } catch (e) {
      this.handleFailedLogin();
      throw e;
    }
  }

  public async login(email: string, password: string): Promise<User | null> {
    const body = {
      email: email,
      password: password
    };
    const response = await this.http.post(this.authenticationRoute(), body, this.apiService.getJsonOptions()).toPromise()
    if (!response) {
      this.handleFailedLogin(true);
      return null;
    }
    await this.handleSuccessfulLogin(User.createFromJson(response));
    return this.currentUser;
  }

  public async loginFromToken(): Promise<User | null> {
    try {
      const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
      if (!token) {
        return null;
      }
      const response = await this.userHttpService.getUser();
      const user = User.createFromJson(response);
      await this.handleSuccessfulLogin(user, false);
      return user;
    } catch (e) {
      throw e;
    }
  }

  private async handleSuccessfulLogin(user: User, showSnackbar = true): Promise<void> {
    this.currentUser = user;
    CookieService.setAccessTokenCookie(user.api_access_token);
    this.currentUserSubject.next(user);
    this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, true);
    if (showSnackbar) {
      this.snackbar.showSimpleSnackbar('Login erfolgreich!');
    }
    this.videoPlayerService.logout.subscribe(() => {
      this.logoutUser();
    });
  }

  private handleFailedLogin(showSnackbar = false): void {
    this.currentUser = null;
    CookieService.deleteCookie(CookieName.ACCESS_TOKEN);
    this.currentUserSubject.next(null);
    this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, false);
    if (showSnackbar) {
      this.snackbar.showSimpleSnackbar('Login fehlgeschlagen!');
    }
  }

  public async facebookLogin(token: string) {
    try {
      const fbLogin = await this.apiService.postFbLogin(token).toPromise();
      if (fbLogin.success) {
        CookieService.setAccessTokenCookie(fbLogin.user.api_access_token);
        this.currentUser = fbLogin.user;
        this.apiService.getJsonOptions();
        this.currentUserSubject.next(fbLogin.user);

        this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, true);
      } else {
        this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, false);
        return false;
      }
    } catch (e) {
      throw e;
    }
  }

  public async appleDigestLogin(api_access_token: string) {
    try {
      await this.apiService.getUserWithApiAccessToken(api_access_token).toPromise().then(response => {
        const respBody = <any>response;
        const user = User.createFromJson(respBody);

        if (user != null) {
          CookieService.setAccessTokenCookie(user.api_access_token);
          this.currentUser = user;
          this.apiService.getJsonOptions();
          this.currentUserSubject.next(user);

          this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, true);
          this.router.navigate(['/']);
        } else {
          this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, false);
          this.router.navigate(['/']);
          this.appStateService.set(this.appStateService.OPEN_SIGNIN_WINDOW, 'digestLoginFailed', true);
        }
      });
    } catch (e) {
      this.router.navigate(['/']);
      this.appStateService.set(this.appStateService.OPEN_SIGNIN_WINDOW, 'digestLoginFailed', true);
      throw e;
    }
  }

  logoutUser(): void {
    CookieService.deleteCookie(CookieName.ACCESS_TOKEN);
    this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, false);
    this.videoPlayerService.resetSubscriptionInfo();
    this.currentUser = null;
    this.currentUserSubject.next(null);
  }

  modifyUserData(userID, user) {
    return this.apiService.modifyUser(userID, user)
  }

  changeUserPassword(userID, passwords: ChangeUserPassword) {
    return this.apiService.changePassword(userID, passwords);
  }

  getCurrentUser(): Promise<User> {
    return new Promise(resolve => resolve(this.currentUser));
  }

  public isGuestUser(): boolean {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.username === null;
  }

  public async followTeam(teamId: string): Promise<boolean> {
    if (!this.currentUser || this.currentUser.isFanOfTeamWithId(teamId)) {
      return false;
    }
    const response = await this.userHttpService.followEntity(FollowEntityType.Team, teamId);
    if (response?.success) {
      this.currentUser.following.teamids.push(teamId);
      return true;
    }
    return false;
  }

  public async unFollowTeam(teamId: string): Promise<boolean> {
    if (!this.currentUser || !this.currentUser.isFanOfTeamWithId(teamId)) {
      return false;
    }
    const response = await this.userHttpService.unfollowEntity(FollowEntityType.Team, teamId);
    console.log('Response: ', response);
    if (response?.success) {
      this.removeEntityIdFromArray(this.currentUser.following.teamids, teamId);
      return true;
    }
    return false;
  }

  public async followLeague(leagueId: string): Promise<boolean> {
    if (!this.currentUser || this.currentUser.isFanOfLeagueWithId(leagueId)) {
      return false;
    }
    const response = await this.userHttpService.followEntity(FollowEntityType.League, leagueId);
    console.log('Response: ', response);
    if (response?.success) {
      this.currentUser.following.leagueids.push(leagueId);
      return true;
    }
    return false;
  }


  public async unFollowLeague(leagueId: string): Promise<boolean> {
    if (!this.currentUser || !this.currentUser.isFanOfLeagueWithId(leagueId)) {
      return false;
    }
    const response = await this.userHttpService.unfollowEntity(FollowEntityType.League, leagueId);
    console.log('Response: ', response);
    if (response?.success) {
      this.removeEntityIdFromArray(this.currentUser.following.leagueids, leagueId);
      return true;
    }
    return false;
  }

  private removeEntityIdFromArray(array: string[], entityId: string): void {
    const index = array.indexOf(entityId);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
