import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { ConsentService } from '@utils/services/shared-consent/consent.service';
import { FacebookService } from '@core/services/facebook.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import {SEOService} from '@core/services/seo.service';
import {StateService} from '@core/services/state.service';
import {WebviewService} from '@utils/services/webview/webview.service';
import {ScrollPositionRestorationService} from '@utils/services/scroll-position-restauration/scroll-position-restoration.service';
import { LayoutService } from '@utils/services/layout/layout.service';
import {ColorScheme} from '@lib/models/color-scheme/color-scheme';
const version = require('../../package.json').version;
const needsConsent = require('../../package.json').version_needs_consent

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  constructor( private facebookService: FacebookService,
               private seoService: SEOService,
               private consentService: ConsentService,
               private scrollPositionService: ScrollPositionRestorationService,
               private navigationService: NavigationService,
               private webviewService: WebviewService,
               private renderer: Renderer2,
               private layoutService: LayoutService) {}

  public async ngOnInit(): Promise<void> {
    // Always dark mode on webview
    if (StateService.isWebview) {
      this.layoutService.setColorScheme(ColorScheme.Dark);
    }
    this.scrollPositionService.init(StateService.isWebview);
    this.consentService.init(!StateService.appTrackingAccepted, version, needsConsent);
    this.navigationService.init();
    if (StateService.isWebview && StateService.isBrowser) {
      this.webviewService.init(this.renderer);
    }
    this.seoService.init();
  }

  public ngOnDestroy(): void {
    this.facebookService.logout();
  }
}
