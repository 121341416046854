import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {SportType} from '@lib/models/sport-type/sport-type';
import {SportsHttpService} from '../../http/sports/sports-http.service';
import {ISport} from '../../models/sports/sport';
import {LayoutService} from '@utils/services/layout/layout.service';
import {WebviewService} from '@utils/services/webview/webview.service';

@Injectable({
  providedIn: 'root',
})
export class SportTypeService {

  public static selectedSportType = SportType.Football;
  public selectedSport: ISport;
  public sports: ISport[] = [];

  constructor(
    private location: Location,
    private sportsHttpService: SportsHttpService,
    private layoutService: LayoutService
  ) {
  }

  public isFootball(): boolean {
    return SportTypeService.selectedSportType === SportType.Football;
  }

  public updateSelectedSport(): void {
    SportTypeService.selectedSportType = this.location.path()?.includes(
      SportType.Handball
    )
      ? SportType.Handball
      : SportType.Football;
    this.setSelectedSport();
  }

  public async getSports(): Promise<void> {
    const response = await this.sportsHttpService.getSports();
    if (!response || !response.mobile || response.mobile.length === 0) {
      return;
    }
    this.sports = response.mobile;
    this.setSelectedSport();
  }

  public setSelectedSport(): void {
    if (!this.sports || this.sports.length === 0) {
      return;
    }
    this.selectedSport = this.sports.find(
      (sport) => sport.type === SportTypeService.selectedSportType
    );

    this.layoutService.updateColorSchemeForSportType(this.selectedSport.type);
  }
}
