<header
  class="header"
  [style.z-index]="layoutService.headerInBackground ? 0 : 1000"
  [class.white-label]="layoutService.whiteLabelHeader"
  [class.dark]="layoutService.darkMode"
  [class.red]="layoutService.redMode">
  <!--Toolbar-->
  <div class="toolbar container">
    <ng-container *ngIf="isFootball">
      <a
        *ngIf="
          layoutService.displayHeaderPromoBanner &&
          !videoPlayerService.isSubscribed
        "
        class="abo-promo"
        [href]="aboLink"
      >
        <h3>JETZT<br/>STREAMEN</h3>
        <img src="assets/icons/fan.at/abo-logo.svg" alt="fan.at abo"/>
      </a>
    </ng-container>
    <a
      *ngIf="!layoutService.whiteLabelHeader"
      [href]="homeLink"
      class="logo"
      [class.d-none]="sportTypeService.sports?.length > 0"
      [class.d-md-block]="sportTypeService.sports?.length > 0"
    >
        <img [src]="LayoutService.getLogo()" alt="fan.at logo"/>
    </a>

    <!-- Sports select for desktop -->
    <div class="sport-type-select desktop d-none d-md-flex">
      <a
        *ngFor="let sport of sportTypeService.sports"
        [class.rise-on-hover-light]="sport.type !== sportTypeService.selectedSport.type"
        [class.selected]="sport.type === sportTypeService.selectedSport.type"
        [href]="getSportFilterLink(sport.type)">
        <mat-icon matPrefix>{{
            sport.type === "handball" ? "sports_handball" : "sports_soccer"
          }}</mat-icon>
        {{ sport.filterName }}
      </a>
    </div>

    <!-- Sports select for mobile -->
    <button
      *ngIf="sportTypeService.selectedSport && sportTypeService.sports?.length > 0"
      class="sport-type-select d-md-none"
      [matMenuTriggerFor]="sportMenu"
    >
      <mat-icon class="sport-icon">{{
        sportTypeService.selectedSport.type === "handball" ? "sports_handball" : "sports_soccer"
        }}</mat-icon>
      {{ sportTypeService.selectedSport.filterName }}
      <mat-icon class="chevron">expand_more</mat-icon>
    </button>
    <mat-menu #sportMenu="matMenu">
      <ng-container *ngFor="let sport of sportTypeService.sports">
        <a mat-menu-item [href]="getSportFilterLink(sport.type)">
          <mat-icon matPrefix>{{
            sport.type === "handball" ? "sports_handball" : "sports_soccer"
            }}</mat-icon>
          {{ sport.filterName }}
        </a>
      </ng-container>
    </mat-menu>
    <a
      *ngIf="!layoutService.whiteLabelHeader"
      [href]="homeLink"
      class="logo logo-small"
      [class.d-block]="sportTypeService.sports?.length > 0"
      [class.d-md-none]="sportTypeService.sports?.length > 0"
    >
      <img [src]="LayoutService.getLogo()" alt="fan.at logo"/>
    </a>
    <a
      *ngIf="layoutService.whiteLabelHeader"
      class="home-button"
      href="https://fan.at"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
      zu fan.at
    </a>
    <!-- <div
      class="backend-switch"
      *ngIf="showBackendSwitch && !layoutService.whiteLabelHeader"
    >
      <span>PROD</span>
      <mat-slide-toggle
        color="primary"
        [checked]="isDevBackend"
        (change)="onBackendToggle($event)"
      ></mat-slide-toggle>
      <span>DEV</span>
    </div> -->
    <div class="search" *ngIf="isFootball">
      <app-searchbar
        #searchBar
        [placeholder]="'Suche nach Teams oder Ligen'"
        [styleMode]="showSearchResults && !searchLoading ? 'droppedDown' : ''"
        [loading]="searchLoading"
        (searchStringChanged)="searchStringChanged($event)"
        (onEnterClick)="searchBarEnterClick()"
      ></app-searchbar>
      <div *ngIf="showSearchResults && !searchLoading" class="results">
        <span
          *ngIf="teams.length === 0 && leagues.length === 0"
          class="no-results"
        >Keine Teams und Ligen gefunden</span
        >
        <ng-container *ngIf="teams.length != 0">
          <span class="heading">Teams</span>
          <a
            *ngFor="let result of teams"
            [href]="result.link"
            class="d-flex align-items-center"
            (click)="clearSearchResults()"
          >
            <img [src]="result.team.getLogoImageUrl('small')" alt="Team Logo"/>
            <div class="team_info d-inline-flex flex-column">
              <span>{{ result.team.name }}</span>
              <span class="state" *ngIf="result.team.getUIName()">{{
                result.team.getUIName()
                }}</span>
            </div>
          </a>
        </ng-container>
        <ng-container *ngIf="leagues.length != 0">
          <span class="heading">Ligen</span>
          <a
            *ngFor="let result of leagues"
            [href]="result.link"
            class="d-flex align-items-center"
            (click)="clearSearchResults()"
          >
            <img
              [src]="result.league.getLogoImageUrl('small')"
              alt="League Logo"
            />
            <div class="league_info d-inline-flex flex-column">
              <span>{{ result.league.name }}</span>
              <span class="state" *ngIf="result.league.getUIStateName()">{{
                result.league.getUIStateName()
                }}</span>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!isFootball" class="hla-logos d-none d-md-flex">
      <img [src]="hlaMeisterligaLogo" alt="hla logo">
      <img [src]="hlaChallengeLogo" alt="hla logo">
    </div>
    <div class="actions">
      <a
        *ngIf="navigationService.useRelative"
        class="action search-btn"
        [routerLink]="navigationService.favouritesLink"
      >
        <mat-icon>search</mat-icon>
        <small>Suche</small>
      </a>
      <a
        *ngIf="!navigationService.useRelative"
        class="action search-btn"
        [href]="navigationService.favouritesLink"
      >
        <mat-icon>search</mat-icon>
        <small>Suche</small>
      </a>
      <a
        *ngIf="!layoutService.whiteLabelHeader"
        class="action"
        [href]="reporterLink"
      >
        <mat-icon>micro</mat-icon>
        <small>Reporter</small>
      </a>
      <button
        *ngIf="!userService.currentUser"
        class="action"
        (click)="onSignIn()"
      >
        <mat-icon>person</mat-icon>
        <small>Login</small>
      </button>
      <button
        *ngIf="userService.currentUser"
        [matMenuTriggerFor]="userMenu"
        class="action"
      >
        <mat-icon>person</mat-icon>
        <small>Profil</small>
      </button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="onEditUserProfile()">
          Profil bearbeiten
        </button>
        <button mat-menu-item *ngIf="!loggedInViaFB" (click)="onEditPassword()">
          Passwort ändern
        </button>
        <button mat-menu-item (click)="onLogout()">Abmelden</button>
      </mat-menu>
    </div>
  </div>
  <!--Navigation-->
  <div
    *ngIf="!layoutService.whiteLabelHeader && layoutService.displayNav"
    class="nav-wrapper toolbar"
  >
    <nav class="container nav">
      <app-global-navigation-wrapper
        *ngIf="this.layoutService.displayMobileTopNav"
        class="d-md-none"
      ></app-global-navigation-wrapper>
      <div class="nav-items">
        <ng-container *ngFor="let link of navigationService.links">
          <a
            *ngIf="
              !navigationService.useRelative || link.isAbsolute;
              else relativeLink
            "
            class="nav-item"
            [class.active]="urlMatches(link.matchUrls || [link.navigateTo], link.ignoreUrls)"
            [href]="link.navigateTo"
          >{{ link.name }}</a
          >
          <ng-template #relativeLink>
            <a
              [class.active]="urlMatches(link.matchUrls || [link.navigateTo], link.ignoreUrls)"
              class="nav-item"
              [routerLink]="link.navigateTo"
            >{{ link.name }}</a
            >
          </ng-template>
        </ng-container>
      </div>
    </nav>
  </div>
</header>
