<div class="picker-container"
     [ngClass]="isSelected ? colorScheme.selected : colorScheme.unselected"
     (click)="selectItem()">
  <lib-lazy-image
    *ngIf="item.logo"
    [picture]="item.logo"
    [targetRes]="imageRes"
    class="logo-image">
  </lib-lazy-image>
  <span *ngIf="item.abbreviation">{{item.abbreviation}}</span>
  <span *ngIf="!item.abbreviation">{{item.name}}</span>
</div>
