import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {INavigationItem, NavigationItemChildAppearance, NavigationItemType} from '@core/components/global-entity-navigation/models/navigation';
import {GlobalEntityNavigationService} from '@core/components/global-entity-navigation/services/global-entity-navigation.service';
import {IMAGE_RESOLUTION} from '@api/models/image/image-resolution';
import {ImageSubType} from '@api/models/image/image-type';
import {NavigationGroupComponent} from '@core/components/global-entity-navigation/navigation-group/navigation-group.component';
import {UrlService} from '@utils/services/url/url.service';
import {NavigationHttpService} from '@core/components/global-entity-navigation/services/navigation-http.service';
import {IDefaultResponse} from '@api/models/default-response/response';
import {FollowEntityType} from '@api/models/user/user-follow';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {
  @Input() item: INavigationItem;

  @ViewChild('followButton') followButtonElement;

  loadingChildren: boolean = false;
  showFollowButton: boolean = false;

  followableEntityType: FollowEntityType;
  followableEntityId: string;
  followableEntityName: string;

  imageSize = IMAGE_RESOLUTION.LOW;
  imageType = ImageSubType.LOGO;

  constructor(public navService: GlobalEntityNavigationService,
              private navHttpService: NavigationHttpService,
              private parentGroup: NavigationGroupComponent,
              private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.showFollowButton = !this.item.has_children && !this.item.child_appearance &&
                            (this.item.type === NavigationItemType.Team || this.item.type === NavigationItemType.League);

    if (this.showFollowButton) {
      this.followableEntityType = this.item.type === NavigationItemType.Team ? FollowEntityType.Team : FollowEntityType.League;
      this.followableEntityId = this.item._id;
      this.followableEntityName = this.item.label;
    }
  }

  /**
   * handles clicks on an item
   * if the item has children and they should be displayed as dropdown, handle directly
   * children that should be displayed as new list are handled in wrapper
   * @param event
   */
  async handleClick(event: Event) {
    if (this.item.children?.length || (event && this.followButtonElement?.nativeElement.contains(event.target as Node))) {
      return;
    }

    if (this.item.has_children && this.item.child_appearance === NavigationItemChildAppearance.Dropdown) {
      this.loadingChildren = true;
      const itemChildrenData: IDefaultResponse = await this.navHttpService.getChildrenForItem(this.item);

      try {
        this.item.children = [...itemChildrenData.data.groups[0].items];
        this.loadingChildren = false;
        this.changeDetectorRef.detectChanges();
        event.preventDefault();
      } catch (error) {
        console.error(error);
        this.loadingChildren = false;
      }

    } else if (this.item.type === NavigationItemType.Url) {
      window.location.href = this.item.url;
    } else {
      if (this.item.readable_id) {
        // TODO pass selection info to new route, can't change subdomain without reload
        const itemUrl = UrlService.getSubdomainUrl(this.item.readable_id);
        window.location.href = itemUrl;
      } else {
        this.navService.itemClicked.emit(this.item);
      }
    }
  }

  get openChildInNewList(): boolean {
    return this.item.child_appearance === NavigationItemChildAppearance.NewList;
  }
}
