<div #adBannerContainer
     *ngIf="!isTestAd || (isTestAd && displayed)"
     class="google-ad-banner"
     [class.test]="isTestAd"
     [ngStyle]="{ minHeight: height, minWidth: width }">
  <h4 class="google-ad-banner__test-text" *ngIf="isTestAd">
    Empty Ad<br><small style="font-size: 0.8em">{{name}}</small>
  </h4>
  <p class="placeholder">Werbung</p>
</div>
