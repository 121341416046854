import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { UserService } from '@app/core/services/user.service';
import { AuthDialogService } from '@app/modules/dialog/services/auth-dialog.service';
import { User } from '@app/shared/models/user';
import { OverlayService } from '@lib-modules/overlays/overlay.service';
import { Subscription } from 'rxjs';
import { AppPromotionComponent } from '../promotions/app-promotion/app-promotion.component';
import {FollowEntityType} from '@api/models/user/user-follow';

@Component({
  selector: 'app-follow-button',
  templateUrl: './follow-button.component.html',
  styleUrls: ['./follow-button.component.scss']
})
export class FollowButtonComponent implements OnInit, OnDestroy {

  @Input() type: FollowEntityType;
  @Input() id: string;
  @Input() name: string;
  user$: Subscription;
  user: User = null;
  isFollowing: boolean = false;

  constructor( private snackbarService: SnackbarService,
               private userService: UserService,
               private authDialogService: AuthDialogService,
               private overlayService: OverlayService ) { }

  ngOnInit(): void {
    this.user$ = this.userService.currentUserSubject.subscribe(user => {
      this.user = user;

      if (this.user) {
        if (this.type === FollowEntityType.Team) {
          this.isFollowing = this.user.isFanOfTeamWithId(this.id) ? true : false;
        }

        if (this.type === FollowEntityType.League) {
          this.isFollowing = this.user.isFanOfLeagueWithId(this.id) ? true : false;
        }
      } else {
        this.isFollowing = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  async onFollowClicked(event: Event) {
    try {
      if (!this.userService.currentUser) {
        const ref = this.overlayService.open(AppPromotionComponent, undefined, undefined, false);
        if (ref !== null) {
          ref.onClosed().subscribe(async () => {

            const response = await this.authDialogService.openLogin();
            if (response) {
              if (this.type === FollowEntityType.Team && this.user.isFanOfTeamWithId(this.id) ||
                  this.type === FollowEntityType.League && this.user.isFanOfLeagueWithId(this.id)) {

                this.snackbarService.showSimpleSnackbar(`Du folgst jetzt ${this.name}`);
              } else {
                this.handleFollowUnfollowAction();
              }
            }
          });

        }
      } else {
        this.handleFollowUnfollowAction();
      }
    } catch (error) {
      console.error(error);
    }
  }

  private handleFollowUnfollowAction() {
    if (this.type === FollowEntityType.Team) {
      this.handleFollowOrUnfollowTeam();
    }

    if (this.type === FollowEntityType.League) {
      this.handleFollowOrUnfollowLeague();
    }
  }

  private async handleFollowOrUnfollowTeam() {
    const response = this.isFollowing ? await this.userService.unFollowTeam(this.id) :
                                        await this.userService.followTeam(this.id);

    this.handleFollowUnfollowResponse(response);
  }

  private async handleFollowOrUnfollowLeague() {
    const response = this.isFollowing ? await this.userService.unFollowLeague(this.id) :
                                        await this.userService.followLeague(this.id);

    this.handleFollowUnfollowResponse(response);
  }

  private handleFollowUnfollowResponse(response: boolean) {
    if (response) {
      const message = this.isFollowing ? `Du folgst ${this.name} nicht mehr` : `Du folgst jetzt ${this.name}`;
      this.snackbarService.showSimpleSnackbar(message);
      this.isFollowing = !this.isFollowing;
    }
  }
}
