import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { AppTeam } from "@shared/models/team";
import { Player } from "@shared/models/player";
import { APIService } from "./api.service";
import { StateService } from "./state.service";
import { Sponsor } from "@shared/models/sponsor";
import { NewsService } from "./news.service";
import { Trainer } from "@shared/models/trainer";
import { ImageService } from "@utils/services/image/image.service";
import { BaseImage } from "@shared/models/image";
import { HttpClient } from "@angular/common/http";
import { Picture } from "./picture";
import { TypeService } from "@core/services/type.service";
import { PageType } from "../../../page-type";
import { LinkService } from "@core/services/link.service";
import { isPlatformBrowser } from "@angular/common";
import { RESPONSE } from "@nguniversal/express-engine/tokens";
import { Response } from "express";
import { TeamsHttpService } from "@api/http/teams/teams-http.service";
import { SERVER_SET_REDIRECT } from "@core/server-injection-tokens";
import { UrlService } from "@utils/services/url/url.service";
import { ITeam } from "@api/models/team/team";

@Injectable({
  providedIn: "root",
})
export class TeamService {
  public static getTeamDetails(
    team: ITeam,
    html = true,
    short = false,
    strongLeague = false
  ): string {
    if (!team.ui_names) {
      return "";
    }
    const data = team.ui_names;
    const separator = html ? "&#32;&#183;&#32;" : " · ";
    let details = ``;
    details += data.state_name ? data.state_name : "Kein Bundesland";
    details += separator;
    const league =
      data.league_name && data.league_name.length > 0
        ? data.league_name
        : "Keine Liga";
    if (strongLeague) {
      details += `<strong>${league}</strong>`;
    } else {
      details += league;
    }
    if (!short) {
      details += separator + team.type;
      details += separator + TeamService.getTeamGenderString(team);
    }
    return details;
  }

  public static getTeamGenderString(team: ITeam): string {
    return this.getGenderString(team.sex);
  }

  public static getGenderString(sex: string): string {
    switch (sex) {
      case "female":
        return "Frauen";
      case "male":
        return "Herren";
      case "youth":
        return "Jugend";
    }
  }

  public currentTeam: AppTeam = undefined;
  private teamsRoute = "teams/";
  private teamsCache = new Map<string, AppTeam>();

  constructor(
    private teamHttpService: TeamsHttpService,
    private apiService: APIService,
    private appStateService: StateService,
    private http: HttpClient,
    private imageService: ImageService,
    private newsService: NewsService,
    @Optional() @Inject(RESPONSE) private serverResponse: Response<any>,
    @Optional()
    @Inject(SERVER_SET_REDIRECT)
    private setRedirect: (oldRid: string, redirectRid: string) => void,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public async getTeam(id: string): Promise<AppTeam> {
    if (id?.length === 0) {
      throw new Error("invalid team id: " + id);
    }
    return new AppTeam(await this.teamHttpService.getTeam(id));
  }

  public getCurrentTeamId(): string {
    return this.currentTeam ? this.currentTeam.id : "";
  }

  public getCurrentTeam(): AppTeam {
    return this.currentTeam;
  }

  public async initCurrentTeam(readableId?: string): Promise<void> {
    if (TypeService.pageType !== PageType.Team) {
      return;
    }

    if (!readableId || readableId.length === 0) {
      return;
    }
    // clear cache (eg for dev/master BE switch)
    this.teamsCache.clear();

    try {
      this.currentTeam = await this.getTeamWithRid(readableId);
    } catch (e) {
      throw e;
    }
  }

  /***************************** HTTP GET FUNCTIONS *****************************/

  public async getTeamWithRid(rid: string): Promise<AppTeam> {
    try {
      const url = this.apiService.getBaseURL() + "readable-id/" + rid;
      const response: any = await this.http
        .get(url, this.apiService.getJsonOptions())
        .toPromise();
      if (!response.success || !response.data) {
        console.error("Could not get data for readable id", rid);
        return null;
      }
      if (response.data.forward && !isPlatformBrowser(this.platformId)) {
        this.serverResponse.redirect(
          LinkService.getLinkForSubdomain(response.data?.forward.readable_id)
        );
        this.setRedirect(rid, response.data.forward.readable_id);
        return null;
      }
      return new AppTeam(response.data.object);
    } catch (e) {
      this.handleError(e);
    }
  }

  fetchTeamsOfLeague(leagueId: string): Promise<AppTeam[]> {
    const url = this.getTeamsOfLeagueRoute(leagueId);

    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => {
        return this.resolveTeams(response);
      })
      .catch((error) => {
        this.handleError(error);
        throw error;
      });
  }

  fetchPopularTeams(countryId: string, limit: number): Promise<AppTeam[]> {
    const url = this.getPopularTeamsRoute(countryId, limit);

    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => this.resolveTeams(response))
      .catch((error) => {
        this.handleError(error);
        return null;
      });
  }

  fetchTeamTrainers(id: string) {
    const url = this.getTeamTrainersRoute(id);

    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => {
        return this.resolveTrainers(response);
      })
      .catch(this.handleError);
  }

  fetchTeamSquad(id: string): Promise<Player[]> {
    const url = this.getTeamSquadRoute(id);

    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => {
        return this.resolvePlayers(response);
      })
      .catch(this.handleError);
  }

  fetchPlayer(playerId: string) {
    const ids: string[] = [];
    ids.push(playerId);
    const url = this.getPlayersRoute(ids);

    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => this.resolvePlayers(response).pop())
      .catch(this.handleError);
  }

  fetchTeamSponsors(id: string) {
    const url = this.getTeamSponsorsRoute(id);

    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => {
        return this.resolveTeamSponsors(response);
      })
      .catch(this.handleError);
  }

  fetchPlayers(ids: string[]) {
    const url = this.getPlayersRoute(ids);
    return this.http
      .get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => this.resolvePlayers(response))
      .catch(this.handleError);
  }

  applyForTeamModeration(
    teamId: string,
    firstName: string,
    lastName: string,
    mail: string,
    phone: string,
    text: string
  ): Promise<{ success: boolean; error: number }> {
    const url = this.apiService.getBaseURL() + `teams/${teamId}/applyasrole`;

    const body = {
      role: "team_reporter",
      first_name: firstName,
      last_name: lastName,
      email: mail,
      tel_nr: phone,
      text: text,
    };

    return this.http
      .post(url, body, this.apiService.getJsonOptions())
      .toPromise()
      .then((response) => {
        if (
          response.hasOwnProperty("success") &&
          response["success"] === true
        ) {
          return { success: true, error: null };
        } else {
          return { success: false, error: null };
        }
      })
      .catch((err) => {
        this.handleError(err);
        if (err.status != null) {
          return { success: false, error: err.status };
        }
        return { success: false, error: null };
      });
  }

  /***************************** RESOLVE FUNCTIONS *****************************/

  resolveTeamSponsors(sponsorsJson) {
    const sponsors = [];
    const length = sponsorsJson.length;
    for (let i = 0; i < length; i++) {
      sponsors.push(this.resolveSponsor(sponsorsJson[i]));
    }
    return sponsors;
  }

  resolveSponsor(sponsorJson) {
    const sponsor = new Sponsor();
    sponsor.id = sponsorJson._id;
    sponsor.name = sponsorJson.name;
    sponsor.type = sponsorJson.type;
    sponsor.url = UrlService.sanitizeUrl(sponsorJson.url);

    for (let i = 0; i < sponsorJson.pictures.length; i++) {
      const img = new BaseImage(sponsorJson.pictures[i]);
      sponsor.images[img.subtype] = img;
    }

    for (let i = 0; i < sponsorJson.pictures.length; i++) {
      const pic = new Picture(sponsorJson.pictures[i]);
      sponsor.pictures.push(pic);
    }

    return sponsor;
  }

  createTeamAbbreviation(name: string) {
    return name.replace(" ", "").slice(0, 3).toUpperCase();
  }

  resolveTrainers(trainersJson: any): Trainer[] {
    const trainers = [];
    const length = trainersJson.length;
    for (let i = 0; i < length; i++) {
      trainers.push(this.resolveTrainer(trainersJson[i]));
    }
    return trainers;
  }

  resolveTrainer(trainerJson: any) {
    return new Trainer(trainerJson);
  }

  resolvePlayers(playersJson: any) {
    const players = [];
    const length = playersJson.length;
    for (let i = 0; i < length; i++) {
      const playerJson = playersJson[i];
      players.push(this.resolvePlayer(playerJson));
    }
    return players;
  }

  resolvePlayer(playerJson: any) {
    return new Player(playerJson);
  }

  resolveTeams(teamJson: any): AppTeam[] {
    const length = teamJson.length;
    const teams = [];
    for (let i = 0; i < length; i++) {
      teams.push(new AppTeam(teamJson[i]));
    }
    return teams;
  }

  /***************************** ROUTE DEFINITIONS *****************************/

  private getTeamRoute(id: string) {
    return this.apiService.getBaseURL() + this.teamsRoute + id;
  }

  private getTeamsOfLeagueRoute(id: string) {
    return this.apiService.getBaseURL() + `leagues/${id}/teams`;
  }

  private getPopularTeamsRoute(countryId: string, limit: number) {
    return (
      this.apiService.getBaseURL() +
      `countries/${countryId}/popularteams?limit=${limit}`
    );
  }

  private getTeamTrainersRoute(id: string) {
    return this.getTeamRoute(id) + "/trainers";
  }

  private getTeamSquadRoute(id: string) {
    return this.getTeamRoute(id) + "/players";
  }

  private getTeamInfoRoute(id: string) {
    return this.getTeamRoute(id) + "/infos";
  }

  private getTeamSponsorsRoute(id: string) {
    return this.getTeamRoute(id) + "/sponsors";
  }

  private getTeamRankingRoute(teamid: string, rankingid: string) {
    return (
      this.apiService.getBaseURL() +
      "rankings/team/" +
      teamid +
      "/" +
      rankingid +
      "/0/10"
    );
  }

  private getPlayersRoute(ids: string[]) {
    const queryString = ids.join("&id=");
    return this.apiService.getBaseURL() + "players/?id=" + queryString;
  }

  private convertColor(color: string) {
    if (color.length === 9) {
      return color.slice(0, color.length - 2); // cut alpha channel of hex (#rrggbbaa --> #rrggbb)
    }
    return color;
  }

  private handleError(error: Response | any) {
    console.log(error);
    return error;
  }
}
