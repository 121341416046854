import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {LAZY_SCRIPT_NAME, LazyScriptLoaderService} from '@utils/services/lazy-script-loader/lazy-script-loader.service';
import {AdBreakpoints, AdConfig} from '../models/ad/ads-config';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ConsentService} from '@utils/services/shared-consent/consent.service';
import {AdCampaignService} from '@components/ad-campaign/services/ad-campaign.service';
import {LocalStorageKey, SessionStorageKey} from '@utils/storage/storage-keys';
import {AdName} from '@lib-modules/ads/models/ad/ad';
import {AdEventType} from '@lib-modules/ads/models/ad-event-type';

@Injectable({
  providedIn: 'root',
})
export class AdService {

  public isHomePage = false;
  public adEvent = new Subject<{ type: AdEventType, adSlotId: string }>();
  public leftSideBarAd = AdName.BesideLeft;
  public rightSideBarAd = AdName.BesideRight;
  public breakpoints = new BehaviorSubject<{ [key: string]: boolean }>({});
  public initialized = false;
  public displayRealAdsSetting = false;
  public displayTestAdsSetting = false;
  private relevantDigital: any;
  private refreshBlocked = false;
  private googleTag: any;
  private activeUnitCount = new Map<AdName, number>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private lazyScriptLoader: LazyScriptLoaderService,
    private breakpointsObserver: BreakpointObserver,
    private consentService: ConsentService,
    private adCampaignService: AdCampaignService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.displayRealAdsSetting = sessionStorage.getItem(SessionStorageKey.RealAdsActive) === 'true';
      this.displayTestAdsSetting = localStorage.getItem(LocalStorageKey.TestAdsActive) === 'true';
      this.registerCampaignChange();
      this.consentService.consentChange.subscribe((consent) => {
        if (consent.action === 'accept') {
          this.init();
        }
      });
    }
  }

  public getAdUnitId(adName: AdName): string {
    // Append unit count for content ads example: .../content_1, .../content_2
    let unitCount = this.activeUnitCount.get(adName) ?? 0;
    unitCount++;
    this.activeUnitCount.set(adName, unitCount);
    return AdConfig.get(adName) + unitCount;
  }

  public async init(): Promise<void> {
    if (!isPlatformBrowser(this.platformId) || this.initialized) {
      return;
    }
    this.initialized = true;
    console.log('[Ads Service] Initialize');
    this.observeBreakpoints();
    await this.trackAdEvents();
    await this.lazyScriptLoader.load(LAZY_SCRIPT_NAME.HB_MANAGER);
    const { relevantDigital } = window as any;
    this.relevantDigital = relevantDigital;
    this.loadAds();
  }

  public loadAds(noSlotReload = true): void {
    if (!this.initialized) {
      return;
    }
    // If a campaign target is set, await campaign load with targeting info before loading ads
    if (!this.adCampaignService.hasTarget() || this.adCampaignService.campaignReady) {
      this.load(noSlotReload);
    }
  }

  public showSidebarAds(show = true): void {
    this.leftSideBarAd = show ? AdName.BesideLeft : null;
    this.rightSideBarAd = show ? AdName.BesideRight : null;
  }

  private load(noSlotReload = true): void {
    console.log('[Ad Service] Load ads called!');
    this.relevantDigital?.cmd.push(() => {
      // For more options see https://help.relevant-digital.com/knowledge/relevantdigital.loadprebid
      this.relevantDigital?.loadPrebid({
        configId: '6399928808a3ef44bc8461ec',
        manageAdserver: true,
        collapseEmptyDivs: true,
        collapseBeforeAdFetch: false,
        allowedDivIds: null,
        noSlotReload,
        delayedAdserverLoading: true,
        onSlotAndUnit: ({ slot }) => this.applyTargeting(slot),
      });
    });
  }

  private async trackAdEvents(): Promise<void> {
    await this.lazyScriptLoader.load(LAZY_SCRIPT_NAME.GOOGLE_GPT);
    const { googletag } = window as any;
    this.googleTag = googletag;
    this.googleTag.cmd.push(() => {
      Object.values(AdEventType).forEach((eventName) => {
        this.googleTag.pubads().addEventListener(eventName, (event) => {
          this.adEvent.next({
            type: eventName,
            adSlotId: event.slot.getSlotElementId()
          });
        });
      });
    });
  }

  public refreshAll(force = false): void {
    if (!this.initialized) {
      return;
    }
    // To conform with ÖWA Rules a refresh should only occur every 15s
    if (!force && this.refreshBlocked) {
      return;
    }
    setTimeout(() => this.refreshBlocked = false, 15000);
    this.refreshBlocked = true;
    console.log(`[Ad Service] REFRESH ALL`);
    this.loadAds(false);
  }

  public destroyAllAds(): void {
    if (!this.initialized) {
      return;
    }
    console.log(`[Ad Service] DESTROY ALL`);
    this.activeUnitCount.clear();
    this.relevantDigital?.destroySlots();
  }

  private registerCampaignChange(): void {
    this.adCampaignService.campaignChange.subscribe(() => {
      this.load();
    })
  }

  private observeBreakpoints(): void {
    const breakPoints = Object.values(AdBreakpoints);
    this.breakpointsObserver.observe(breakPoints).subscribe((result) => {
     this.breakpoints.next(result.breakpoints);
    });
  }

  private applyTargeting(slot): void {
    slot.waitGamSlot((gamSlot) => {
      const targetingInfo = this.adCampaignService.getAdTargeting();
      if (targetingInfo?.value?.length) {
        gamSlot.setTargeting(targetingInfo.channel_name, targetingInfo.value);
      }
      if (this.isHomePage) {
        gamSlot.setTargeting('fan.at_channel', 'homepage');
      }
    });
  }
}
