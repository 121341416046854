import {Injectable, OnDestroy} from '@angular/core';
import {PromotionService} from '@core/services/promotion/promotion.service';
import {IPromotionEmbodimentData, PromotionEmbodiment} from '@core/services/promotion/promotions';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BehaviourAnalyticsService} from '@core/services/behaviour-analytics/behaviour-analytics.service';
import {StateService} from '@core/services/state.service';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {Cookies} from '@utils/services/cookie/cookies';
import { IPromotionBanner } from '@api/models/promotions/promotion-banner-response';
import { PromotionsHttpService } from '@api/http/promotions/promotions-http.service';
import { UserJourney, UserJourneyPhase } from '@api/models/promotions/funnel';

@Injectable({
  providedIn: 'root'
})
export class AppPromotionService extends PromotionService implements OnDestroy {

  public registrationUserFunnel: {
    promoBanner: IPromotionBanner,
    successBanner: IPromotionBanner,
  };
  private readonly pageViewLimit = 30;
  private readonly sessionLimit = 12;
  private readonly timeoutLimit = 3 * 24 * 60 * 1000; // 3 days
  private sessionChange$: Subscription;
  private pageChange$: Subscription;
  private firstSession = false;

  constructor(
    private behaviourAnalyticsService: BehaviourAnalyticsService,
    private promosHttp: PromotionsHttpService,
  ) {
    super(PromotionEmbodiment.AppTeaser);
    this.displayEmitter = new BehaviorSubject<IPromotionEmbodimentData>(this.visibleState);
    this.pageChange$ = this.behaviourAnalyticsService.pageChange.subscribe(() => this.addPageView());
    this.sessionChange$ = this.behaviourAnalyticsService.sessionChange.subscribe((sessionNr) => {
      if (sessionNr != null) {
        this.addSession(sessionNr)
      }
    });
  }

  ngOnDestroy(): void {
    this.pageChange$.unsubscribe();
    this.sessionChange$.unsubscribe();
  }

  public async getRegistrationFunnel(): Promise<void> {
    if (this.registrationUserFunnel) {
      return;
    }
    this.registrationUserFunnel = { promoBanner: null, successBanner: null };
    this.registrationUserFunnel.promoBanner = await this.promosHttp.getFunnelByJourneyAndPhase(
      UserJourney.Register, UserJourneyPhase.Promo
    );
    this.registrationUserFunnel.successBanner = await this.promosHttp.getFunnelByJourneyAndPhase(
      UserJourney.Register, UserJourneyPhase.Success
    );
    console.log('Funnel: ', this.registrationUserFunnel)
  }

  shouldShow(): void {
    if (StateService.isWebview) {
      return;
    }

    let displayValue;

    const pageViews = Number(CookieService.getCookie(CookieName.PROMO_APP_TEASER_COOKIE_PAGES));
    const sessions = Number(CookieService.getCookie(CookieName.PROMO_APP_TEASER_SESSIONS));
    const lastDisplay = Number(CookieService.getCookie(CookieName.PROMO_APP_TEASER_LAST_DISPLAY));

    // WM 2022: Add "|| this.firstSession" here
    displayValue = pageViews >= this.pageViewLimit ||
                   sessions >= this.sessionLimit ||
                   !isNaN(lastDisplay) && lastDisplay - Date.now() - this.timeoutLimit > 0;

    // WM 2022: Remove
    if (this.firstSession && pageViews >= 3) {
      displayValue = true;
    }

    if (displayValue === true) {
      this.resetCounters();
    }

    this.doAfterShouldShow(displayValue);
  }

  private addPageView(): void {
    let currentValue = Number(CookieService.getCookie(CookieName.PROMO_APP_TEASER_COOKIE_PAGES)) ?? 0;
    currentValue++;

    CookieService.setCookie(Cookies[CookieName.PROMO_APP_TEASER_COOKIE_PAGES], currentValue.toString());
    this.shouldShow();
  }

  private addSession(sessionNr: number): void {
    let currentValue = Number(CookieService.getCookie(CookieName.PROMO_APP_TEASER_SESSIONS)) || 0;
    currentValue++;

    CookieService.setCookie(Cookies[CookieName.PROMO_APP_TEASER_SESSIONS], currentValue.toString());

    if (sessionNr === 1) {
      this.firstSession = true;
    }

    this.shouldShow();
  }

  resetCounters(): void {
    this.firstSession = false;
    CookieService.deleteCookie(CookieName.PROMO_APP_TEASER_COOKIE_PAGES);
    CookieService.deleteCookie(CookieName.PROMO_APP_TEASER_SESSIONS);
    CookieService.setCookie(Cookies[CookieName.PROMO_APP_TEASER_LAST_DISPLAY], Date.now().toString());
  }
}
