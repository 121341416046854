import {Injectable} from '@angular/core';
import {NewsEntry} from './newsEntry';
import {APIService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {INewsPost} from '@api/models/news/news';

export interface PaginatedNewsResponse {
  entries: NewsEntry[];
  total_count: number;
}

@Injectable({
  providedIn: 'root',
})
export class NewsService {

  news = [];

  public static replaceFacebookImportTitle(news: INewsPost): string {
    let title = news.title;
    // Replace facebook import titles
    if (title && news.media?.facebookURL && news.preview_text?.length > 0) {
      if (title.includes('Fotos von') || title.includes('Photos from')) {
        title = news.preview_text;
      }
    }
    return title;
  }

  constructor(
    private apiService: APIService,
    private http: HttpClient,
  ) { }

  getNews() {
    return this.news.slice();
  }

  getNewsEntry(id: string): Promise<NewsEntry> {
    return new Promise((resolve, reject) => {
      const filtered = this.news.filter(e => e.id === id);
      if (filtered.length > 0) {
        resolve(filtered[0]);
      } else {
        resolve(this.fetchNewsEntry(id));
      }
    });
  }

  getFanAtNews(skip: number, limit: number, pictures: boolean): Promise<PaginatedNewsResponse> {
    let url = this.getFanAtNewsRoute(skip, limit);

    if (pictures) {
      url = url + '?pictures=true';
    }

    return this.http.get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then(response => this.resolvePaginatedNews(response))
      .catch(error => {
        this.handleError(error);
        return null;
      })
  }

  getLatestTeamNews(skip: Number, count: Number, beautiful: boolean) {
    let url =  this.apiService.getBaseURL() + 'v2/news/latest/' + skip + '/' + count;

    if (beautiful) {
      url =  url + '?beautiful=true'
    }

    return this.http.get(url, this.apiService.getJsonOptions())
      .toPromise()
      .then(response => this.resolvePaginatedNews(response))
      .catch(error => {
        this.handleError(error);
        return null;
      })
  }

  /***************************** HTTP GET FUNCTIONS *****************************/

  fetchNewsEntry(id: string): Promise<NewsEntry> {
    return this.http.get(this.getNewsEntryRoute(id), this.apiService.getJsonOptions())
      .toPromise()
      .then(response => this.resolveNewsEntry(response))
      .catch(error => {
        this.handleError(error);
        return null;
      });
  }

  fetchTeamNewsPaginated(teamid: string, from: number, count: number, params: {} = null): Promise<PaginatedNewsResponse> {
    const url = this.getTeamNewsPaginatedRoute(teamid, from, count);

    return this.http.get(url, this.apiService.getJsonOptions(params))
      .toPromise()
      .then(response => this.resolvePaginatedNews(response))
      .catch(this.handleError);
  }

  fetchLeagueNewsPaginated(leagueId: string, skip: number, limit: number): Promise<PaginatedNewsResponse> {
    return this.http.get(this.getLeagueNewsPaginatedRoute(leagueId, skip, limit), this.apiService.getJsonOptions())
      .toPromise()
      .then((response: Response) => {
        return this.resolvePaginatedNews(response);
      })
      .catch(this.handleError);
  }

  fetchNewsOfUser(skip, limit): Promise<PaginatedNewsResponse> {
    return this.http.get(this.getUserNewsPaginatedRoute(skip, limit), this.apiService.getJsonOptions())
      .toPromise()
      .then((response: Response) => {
        return this.resolvePaginatedNews(response);
      })
      .catch(this.handleError);
  }

  fetchTeamNewsForState(stateid: string, count: number = 5) {
    return this.http.get(this.getStatewideNewsRoute(stateid, count), this.apiService.getJsonOptions())
      .toPromise()
      .then((response: Response) => {
        return this.resolvePaginatedNews(response);
      })
      .catch(this.handleError);
  }

  fetchTeamNewsForCountry(countryid: string, count: number = 5) {
    return this.http.get(this.getCountrywideNewsRoute(countryid, count), this.apiService.getJsonOptions())
      .toPromise()
      .then((response: Response) => {
        return this.resolvePaginatedNews(response);
      })
      .catch(this.handleError);
  }

  fetchTeamNewsForPerformanceLevel(level: number, skip: number = 0, limit: number = 5) {
    return this.http.get(this.getPerformanceLevelNewsRoute(level, skip, limit), this.apiService.getJsonOptions())
      .toPromise()
      .then((response: Response) => {
        return this.resolvePaginatedNews(response);
      })
      .catch(this.handleError);
  }

  /***************************** RESOLVE FUNCTIONS *****************************/

  resolvePaginatedNews(jsonNews: any): PaginatedNewsResponse {
    const jsonEntries = jsonNews.data;
    const total_count = jsonNews.total_count;
    const news = [];

    const length = jsonEntries.length;
    for (let i = 0; i < length; i++) {
      news.push(this.resolveNewsEntry(jsonEntries[i]));
    }
    return {entries: news, total_count: total_count};
  }

  resolveNewsEntry(jsonEntry): NewsEntry {
    return new NewsEntry(jsonEntry);
  }

  /***************************** ROUTE DEFINITIONS *****************************/

  getTeamNewsPaginatedRoute(teamid: string, skip: number, limit: number): string {
    return this.apiService.getBaseURL() + `v2/news/for/team/${teamid}/${skip}/${limit}/`;
  }

  getLeagueNewsPaginatedRoute(id: string, skip: number, limit: number): string {
    return this.apiService.getBaseURL() + `v2/news/for/league/${id}/${skip}/${limit}/`;
  }

  getUserNewsPaginatedRoute(skip: number, limit: number): string {
    return this.apiService.getBaseURL() + `v2/news/user/${skip}/${limit}/`;
  }

  getNewsEntryRoute(id): string {
    return this.apiService.getBaseURL() + `v2/news/${id}/`;
  }

  getFanAtNewsRoute(skip: number, limit: number): string {
    return this.apiService.getBaseURL() + `v2/news/fan-at/${skip}/${limit}/`;
  }

  getStatewideNewsRoute(stateid: string, count: number) {
    return this.apiService.getBaseURL() + `v2/news/for/state/${stateid}/0/${count}?krone=false&beautiful=true`;
  }

  getCountrywideNewsRoute(countryid: string, count: number) {
    return this.apiService.getBaseURL() + `v2/news/for/country/${countryid}/0/${count}?krone=false&beautiful=true`;
  }

  getPerformanceLevelNewsRoute(level: number, skip: number, limit: number) {
    return this.apiService.getBaseURL() + `v2/news/level/${level}/${skip}/${limit}?krone=false&beautiful=true`;
  }

  handleError(error) {
    console.log(error);
    return error;
  }

}

