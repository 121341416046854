export const REPORTS_ROUTE = 'reports';
export const LEAGUES_ROUTE = 'leagues';
export const EVENTS_ROUTE = 'events';
export const TEAMS_ROUTE = 'teams';
export const OFFICIALS_ROUTE = 'officials';
export const SPONSORS_ROUTE = 'sponsors';
export const PLAYERS_ROUTE = 'players';
export const TRAINERS_ROUTE = 'trainers';
export const SHARE_ROUTE = 'share';
export const LINEUP_ROUTE = 'lineup';
export const RANKING_ROUTE = 'ranking';
export const ACTIVITY_ROUTE = 'activity';
export const POST_ROUTE = 'post';
export const UPLOAD_ROUTE = 'upload';
export const NAVIGATION_ROUTE = 'navigation';
export const PROFILE_ROUTE = 'newsprofiles';
export const SEARCH_ROUTE = 'search';
export const INVITES_ROUTE = 'invites';
export const VOTINGS_ROUTE = 'votings';
export const RAFFLE_ROUTE = 'raffles';
export const STATES_ROUTE = 'states';
export const USERS_ROUTE = 'users';
export const COMPOSITE_TEAMS_ROUTE = 'compositeteams';
export const VIDEO_TICKER_ROUTE = 'video-ticker';
export const AD_CAMPAIGNS_ROUTE = 'ad-campaigns';
export const PROMOTIONS_BANNER_ROUTE = 'promos/banner';
export const PROMOTIONS_FUNNEL_ROUTE = 'promos/funnel';
export const CTA_BANNER_ROUTE = 'promos/cta-banner';
export const SUBSCRIPTIONS_ROUTE = 'subscriptions';
export const EVENTS_STREAMING_ROUTE = 'v2/events/streaming';
export const READABLE_ID_ROUTE = 'readable-id';
export const CAMERAS_ROUTE = 'cameras';
export const LIVESTREAMS_ROUTE = 'livestreams';
export const STREAMING_ROUTE = 'streaming';
export const VARIABLES_ROUTE = 'variables';

export const NEWS_ROUTE = 'v2/news';
export const LEAGUES_ROUTE_NEW = 'v2/leagues';
export const EVENTS_ROUTE_NEW = 'v2/events';
export const TEAMS_ROUTE_NEW = 'v2/teams';
export const PLAYERS_ROUTE_NEW = 'v2/players';
export const FACEBOOK_FEEDS_ROUTE = 'v2/facebook/feeds';
export const FACEBOOK_FEED_ROUTE = 'v2/facebook/feed';
export const FACEBOOK_ROUTE = 'v2/facebook';
export const STATES_ROUTE_NEW = 'v2/states';
export const SEASONS_ROUTE_NEW = 'v2/seasons';
export const VIDEOS_ROUTE_NEW = 'v2/videos';
export const VIDEOS_TAB_ROUTE = 'v2/videos/merged/tab';
export const USERS_ROUTE_NEW = 'v2/users';
export const REPORTER_MESSAGING_ROUTE = 'v2/reporter-messaging';
export const ANALYTICS_ROUTE = 'v2/analytics';
export const TICKER_COMMENT_ROUTE = 'v2/posts';
export const TICKER_ACTIVITY_ROUTE = 'v2/activities';
export const EVENTS_FILTERED = 'v2/events/filter';
export const READABLE_ID_LIST_ROUTE = '/v2/sitemaps/readableids';
export const RANKINGS_ROUTE = 'v2/rankings';
export const RANKINGS_REPORTER_OUTLINE = RANKINGS_ROUTE + '/reporter/outline';
export const RANKINGS_ACTIVE_CLUBS_OUTLINE = RANKINGS_ROUTE +  '/active/clubs/outline';
export const BETTING_GAMES_ROUTE = 'v2/bettinggames';
export const OFFICIALS_ROUTE_V2 = 'v2/officials';
export const TRANSFERS_ROUTE = 'transfers/for'


