<app-top-navigation-bar *ngIf="layoutService.displayHeader"></app-top-navigation-bar>
<store-banner *ngIf="showStoreBanner && layoutService.displayHeader"></store-banner>
<div class="content"
     [class.no-navigation]="layoutService.whiteLabelHeader || !layoutService.displayNav"
     [class.no-navigation-m]="!layoutService.displayMobileTopNav"
     [class.no-header]="!layoutService.displayHeader">
  <div class="outlet-wrapper">
    <lib-ad *ngIf="adService.leftSideBarAd" class="app-ad__sidebar left"
            [showPlaceholder]="false"
            [name]="adService.leftSideBarAd"
            [breakpoints]="[adBreakPoints.BesideLeft]"></lib-ad>
    <router-outlet></router-outlet>
    <lib-ad
      *ngIf="adService.rightSideBarAd" class="app-ad__sidebar right"
      [showPlaceholder]="false"
      [name]="adService.rightSideBarAd"
      [breakpoints]="[adBreakPoints.BesideRight]"
    ></lib-ad>
  </div>
  <button mat-raised-button
          class="go-up mat-elevation-z6"
          *ngIf="layoutService.showScrollUp"
          [class.shown]="showScrollUp"
          [class.has-nav]="layoutService.displayNav"
          (click)="onUp()">
    <mat-icon>trending_flat</mat-icon>
    Zurück
  </button>
  <app-footer *ngIf="layoutService.displayFooter"></app-footer>
  <app-bottom-navigation-bar
    *ngIf="layoutService.displayNav"
    class="d-block d-md-none"
  ></app-bottom-navigation-bar>
</div>
<div *ngIf="layoutService.displayNav" class="d-block d-md-none">
  <app-mobile-reporter-buttons></app-mobile-reporter-buttons>
</div>
