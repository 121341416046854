import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

interface Scripts {
  name: string;
  src: string;
}

export enum LAZY_SCRIPT_NAME {
  HB_MANAGER = "hb-manager",
  COOKIE_SCRIPT_PROD = "cookie-script-prod",
  COOKIE_SCRIPT_TEST = "cookie-script-test",
  GOOGLE_GPT = "google-publisher-tags",
  GOOGLE_RECAPTCHA_V2 = "google-recaptcha-v2",
  APPLE_AUTH = "appl-auth",
  GOOGLE_MAPS = "google-maps",
  INSTAGRAM_SDK = "instagram",
  TWITTER_SDK = "twitter",
  FACEBOOK_SDK = "facebook",
  STAIGE_PLAYER = "staige-player",
}

export const LazyScriptStore: Scripts[] = [
  {
    name: LAZY_SCRIPT_NAME.STAIGE_PLAYER,
    src: "https://storage.googleapis.com/aisw-assets/player/latest/aisw-player.js",
  },
  {
    name: LAZY_SCRIPT_NAME.HB_MANAGER,
    src: "https://krone-cdn.relevant-digital.com/static/tags/63973256407665796c7244b9.js",
  },
  {
    name: LAZY_SCRIPT_NAME.COOKIE_SCRIPT_TEST,
    src: "//cdn.cookie-script.com/s/d6f76628a66435d08707dbf23b69e810.js",
  },
  {
    name: LAZY_SCRIPT_NAME.COOKIE_SCRIPT_PROD,
    src: "//cdn.cookie-script.com/s/b8afff2cbc638144506a12f95a696c39.js",
  },
  {
    name: LAZY_SCRIPT_NAME.INSTAGRAM_SDK,
    src: "https://platform.instagram.com/de_DE/embeds.js",
  },
  {
    name: LAZY_SCRIPT_NAME.TWITTER_SDK,
    src: "https://platform.twitter.com/widgets.js",
  },
  {
    name: LAZY_SCRIPT_NAME.GOOGLE_GPT,
    src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
  },
  {
    name: LAZY_SCRIPT_NAME.GOOGLE_RECAPTCHA_V2,
    src: "https://www.google.com/recaptcha/api.js?onload=grecaptchaloaded&render=explicit",
  },
  {
    name: LAZY_SCRIPT_NAME.FACEBOOK_SDK,
    src: "https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v7.0",
  },
  {
    name: LAZY_SCRIPT_NAME.GOOGLE_MAPS,
    src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBx_O67-xQz9gcjRaUHf6NAtF4byb2uf2U",
  },
  {
    name: LAZY_SCRIPT_NAME.APPLE_AUTH,
    src: "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
  },
];

declare var document: any;

@Injectable({
  providedIn: "root",
})
export class LazyScriptLoaderService {
  private scripts: any = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    LazyScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  public load(...scripts: string[]) {
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }
    console.log("[Lazy Script] Loading ", scripts);
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.scripts[name].src;
        script.defer = true;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: "Loaded" });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: "Loaded" });
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      }
    });
  }
}
